.bullet-label{
    font-size: 12px !important;
    }
    .label-style{
        text-align: left;
        line-height: 1;
        font-size: 12px;
        font-weight: 400;
    }
    .head-style{
        text-align: left;
        line-height: 1;
        font-size: 12px;
        font-weight: 600;
    }