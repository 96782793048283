.vertical-tabs {
  display: flex;
  flex-direction: row;
  background-color: #d2ddf9;
  /* width: 78%; */
}

.vertical-tab-list {
    display: flex;
    flex-direction: column;
    width: 13%;
    height: fit-content;
    flex-shrink: 0;
    border-radius: 15px;
    background: #FFF;
    font-size: 16px;
    font-weight: 500;
    font-family: "Segoe UI Historic";
    padding: 5px;
}

.link.active-leftNav {
  color: #c22828;
  background-color: #000;

}

.linkselectedmenu {
  display: flex;
  border-radius: 10px;
  gap: 10%;
  background: #0866ff;
  color: #fff;
  transition: all 0.5s;
  padding: 5px;

}

.link {
  display: flex;
  color: #000;
  border-radius: 10px;
  gap: 10%;
  transition: all 0.5s;
  padding: 5px;

}

.link:hover,
.link:active {
  background: #0898ff;
  color: #fff;
  transition: all 0.5s;
}

.icon,
.link_text {
  font-size: 16px;
}

.container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: flex-start;
    padding-right: 0px !important;
    padding-left: 0px !important;
}


.submenu-container {
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

}

.hidden-tab-list {
  display: none;
}

.template-chooser-page {
  display: flex;
  width: 100%;
}

.page-content {
  display: flex;
  width: 100%;
}

.form-container {
  width: 70%;
  /* Adjust this value as needed */
  padding: 20px;
}

.container-fluid {
 padding: 10px;
  width: 100%;
  min-height: 80vh;
  height: fit-content;
  border-radius: 20px;
  /* padding: 1%; */
  /* background-color: var(--c-bg-primary); */
}

.right-wrapper {
    margin-left: 10px;
    background-color: #ffffff;
    border-radius: 15px;
    max-height: 95vh;
    /*min-height: 80vh;*/
    padding: 5px;
    padding-bottom: 8px;
}
.scrollnew {
  overflow-y: scroll;
  height: 65vh;
}
.scroll-Dash {
  overflow-y: scroll;
  height: 75vh;
}
@media (max-width: 600px) {
  .link_text {
    display: none;
  }
  .container-fluid{
    padding: 0%;
  }
}



.modal-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content-popup {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 570px;
    text-align: center;
}

.modal-buttons-popup {
    margin-top: 10px;
    display: flex;
    gap: 5px;
    margin-left: 325px;
}

    .modal-buttons-popup button {
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

        .modal-buttons-popup button:first-child {
            background-color: #007bff;
            color: white;
            width: 100px;
            height: 28.5px;
        }

        .modal-buttons-popup button:last-child {
            background-color: #b72a2a;
            color: white;
            width: 100px;
            height: 28.5px;
        }
