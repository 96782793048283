@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&family=Space+Mono:wght@400;700&display=swap');

*, *:after, *:before {
	box-sizing: border-box;
}

:root {
	--c-text-primary: #162121;
	--c-text-secondary: #6D7680;
	--c-action-primary: #F29873; 
	--c-action-primary-accent: #FAF5F1;
	--c-action-secondary: #63C4B1;
	--c-action-tertiary: #F9BE31;
	--c-bg-primary: #FCFCFC;
	--c-bg-secondary: #F7F6FC;
	--c-bg-search: #F0F4F5;
}

  label {
    cursor: pointer;
    color: #555;
    display: block;
    padding: 1px;
    margin: 1px;
  }

  input[type=text], input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    // border: 1px solid #ccc;
    box-sizing: border-box;
    color: #000;
  }
  input[type=textnew] {
    width: 100%;
    padding: 12px 20px;
    // margin: 8px 0;
    display: inline-block;
    // border: 1px solid #ccc;
    box-sizing: border-box;
    color: #000;
  }

body {
    font-family: "Segoe UI Historic";
    line-height: 1.0;
    min-height: 100vh;
    background-color: var(--c-action-primary-accent);
    color: var(--c-text-primary);
    // padding: 2rem;
    padding-bottom: 0;
}

/* body {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  margin: 0;
} */

input, select, button, textarea {
	font: inherit;
	color: inherit;
	outline: 0;
}

.App{
  box-shadow: 0 0 2px 0 rgba(#000, .1), 0 15px 30px rgba(#444, .1);
	// padding: 3rem;
	border-radius: 15px;
	max-width: 1400px;
	margin-left: auto;
	margin-right: auto;
	background-color: var(--c-bg-primary);
  background-image:  url("https://neocmsimages.s3.amazonaws.com/images/logos/cmshomepage.jpg");
	min-height: calc(100vh - 4rem);
  display: flex;
  align-items: center;
  margin-top: 4rem;
  
}

.App2 {
   
    width: 100% !important;
  
    min-height: calc(50vh - 4rem) !important;
   
    margin-top: 2px !important;
}



.AppLink{
  box-shadow: 0 0 2px 0 rgba(#000, .1), 0 15px 30px rgba(#444, .1);
	padding: 3rem;
	border-radius: 15px;
	max-width: 1400px;
  height: 32rem;
	margin-left: 1%;
	margin-right: 800px;
  width: 24rem;
  margin-top: 3rem;
	background-color: var(--c-action-primary-accent);
	min-height: calc(100vh - 4rem);
  flex-shrink: 0;
  padding-right: 90px;
  margin-bottom: 3rem;
}

img {
	display: block;
	max-width: 100%;
}
.main {
	margin-top: 6rem;
	display: grid;
	grid-template-columns: 1fr 4fr;
	column-gap: 5rem;
}

.tabs {
	display: flex;
	flex-direction: column;
}

.tab {
	display: flex;
	&+.tab {
		margin-top: .5rem;
	}
}

.tab-btn {
    border: none;
    background-color: rgb(94, 126, 131);
    padding: -5.25rem;
    border-radius: 6px;
    display: flex;
    align-items: center;
    color: #1c3944;
    width: 100%;
    font-weight: 550;
    font-size: 16px;
    i {
      margin-right: .75rem;
      font-size: 1.25em;
      color: var(--c-text-secondary);
    }
    &--active, &:hover {
      color: var(--c-action-primary);
      background-color: var(--c-bg-search);
      cursor: pointer;
      i {
        color: var(--c-action-primary);
      }
    }
    
    &--active {
      font-weight: 600;
    }
  }
  

  .main-content {
    background-color: #EEE;
  }
  




h1{
  text-align: center;
  margin: 0 0 23px;
}
form {
  // padding: 30px 40px;
}

.form-control {
  margin-bottom: 10px;
  padding-bottom: 20px;
  position: relative;
}
.form-control label {
  color: #777;
  display: block;
  margin-bottom: 5px;
}
.form-control input {
  border: 2px solid #f0f0f0;
  border-radius: 4px;
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 14px;
}
.form-control input:focus {
  outline: 0;
  border-color: #777;
}

.form-control select-selected {
  position: absolute;
  background-color: #777;
  width: 100%;

}

.custom-select {
  position: relative;
}

.form-control select-selected::after {
  position: absolute;
  content: "";
  top: 15px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.form-control.success input {
  border-color: green;

}
.form-control.error input {
  border-color: red;
}
.form-control p {
 /*position: absolute;
  bottom: 0;
  left: 0;*/
  display: none;
}
.form-control.error p{
  display: inline;
  font-size:12px;
}
button {
  cursor: pointer;
  background-color: #3498db;
  border: 2px solid #3498db;
  border-radius: 2px;
  font-size: 16px !important;
  padding: 10px;
  margin-top: 20px;
  width: 100%;
  display: block;
}

