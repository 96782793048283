/* styles.css */
.header-bar {
    background-color: #0866ff;
    color: white;
    padding: 16px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  
  .search-bar-container {
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
    margin-top: 1%;
    padding: 1%;
    gap: 1%;
  }
  
  .search-bar {
    flex: 1;
    margin-right: 16px;
    min-width: 80%;
  }
  
  .table-container {
    padding: 16px;
  }
  
  .table-header {
    background-color: #e0e0e0;
    position: sticky;
    top: 0;
    z-index: 1; 
  }
  
  .action-buttons {
    display: flex;
    gap: 10%;
  }
  
  .action-button {
    color: #0866ff;
    width: 10%;
  }
  .add-btn{
    max-width: 10%;
    background-color: green;
    color: white;
    align-items: center;
    justify-content: center;
  }
  
  .scroll-Dash {
    overflow-y: scroll;
    height: 75vh;
}

.table-wrapper {
  height: 75vh;
  overflow-y: auto;
}



.table-body {
  overflow-y: auto;
  display: block;
  max-height: calc(75vh - 48px); 
}
