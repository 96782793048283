.header{
    padding: 0px 0;
    text-align: center;
  background: #222;
/* 
  position: absolute; */
  top: 0;
  width: 100%;
  left: 0;
  margin: 0rem;
 
}
.header img {
    width: 100px;
    height: 100px;
}

.header-avatar {
	border: none;
    border-radius: 20px;
    background-color: #222;
    display: flex;
    align-items: center;
    margin-right: 1rem;
	padding:0px;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header-logo {
	font-family: "Segoe UI Historic";
	font-weight: 700;
	font-size: 1.5rem;
}

.header-content {
	margin-left: auto;
	display: flex;
	flex-wrap: wrap;
}

.header-search {
	display: flex;
	position: relative;
}

.header-avatar-img {
	display: block;
	width: 35px;
	height: 35px;
	border-radius: 5%;
	background-image: url("https://neocmsimages.s3.amazonaws.com/images/logos/default-avatar.png");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.header-avatar-name {
	margin-left: 5px;
	text-transform: capitalize;
	font-size:14px;
}

.logout-dropdown{
	padding: 1rem;
    color: rgb(255, 255, 255);
    background: #222;
    position: absolute;
    margin-top: 0.2rem;
    margin-right: 0;
    right: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 8.5rem;
    box-shadow: -4px 11px 19px 0px #33333396;
	z-index: 1 ;

}



.logout-option {
	margin: 0;
	font-size: 14px !important;
	padding-top: 1rem;
	padding-bottom: 1rem;
	cursor: pointer;
	border-radius: 5px;
	font-family: "Segoe UI Historic" !important;
}

.logout-option:hover{
	background: rgba(80, 152, 247, 0.507);
}


.butn {

	position: relative;
	height: 3.5em;

	outline: none;
	background-color: transparent;
	color: white;
	transition: 1s;
	border-radius: 0.3em;
	font-size: 16px;
	font-weight: bold;
	cursor: pointer;
  }
  

  
  