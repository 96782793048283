.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
  }
  
  .reset-password {
    max-width: 100%;
    /* padding: 20px; */
    /* border: 1px solid #ccc; */
    /* border-radius: 8px; */
    background-color: #fff; /* Changed background to white for better contrast */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); Added box shadow for depth */
  }
  
  .reset-password h2 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }

    .reset-password input {
        width: 100%;
        padding: 8px;
        margin-bottom: 15px;
        border: 1px solid rgba(105, 105, 105, 0.397);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: 'Segoe UI Historic'
    }
  
  .reset-password button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .reset-password button:hover {
    background-color: #0056b3;
  }
  
  .reset-password p {
    margin-top: 10px;
    font-size: 14px;
    color: #555;
    text-align: center;
  }
 .q{
    font-size: 14px !important;
    font-family: 'Segoe UI Historic' !important;
    line-height: 1.0;
    margin-left: 25px;
}