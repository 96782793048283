/* The Modal (background) */
.modal {
  box-shadow: 0 0 2px 0 rgba(#000, .1), 0 15px 30px rgba(#444, .1);
  position: fixed;
  /* Stay in place */
  padding: 3rem;
  border-radius: 15px;
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 60px;
  size: A4;
}

.form-heading {
  text-align: left;
  margin-left: 2px;
  font-size: 2rem;
  font-family: "Segoe UI Historic" !important;
}

.submodal {
  box-shadow: 0 0 2px 0 rgba(#000, .1), 0 15px 30px rgba(#444, .1);
  position: fixed;
  /* Stay in place */
  padding: 8rem;
  border-radius: 25px;
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  padding-top: 60px;
  size: A4;
}

@media (max-width: 768px) {
  .submodal {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }
}

/* Modal Content/Box */
.modal-content {

  margin: 9% auto 15% auto;
  /* 5% from the top, 15% from the bottom and centered */
  border-radius: 15px;
  border-radius: 17px;
  width: 90%;
  background-color: #fefefe;
  box-shadow: -5px 5px 100px #000000,
    5px -5px 100px #ffffff;
  /* Could be more or less, depending on screen size */

}

.modal-content-2 {
  margin: 65px !important;
}

.last-end {
  display: flex;
  justify-content: flex-end;
  padding: 7px;
}

.modal-content-signin {
  /* margin: 9% auto 15% auto;
  border-radius: 15px;
  border-radius: 17px;
  box-shadow: -5px 5px 100px #000000,
    5px -5px 100px #ffffff; */
  background-color: #d2ddf9;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
}


.modal-new {

  margin: 9% auto 15% auto;
  /* 5% from the top, 15% from the bottom and centered */
  min-width: 50%;
  /* Could be more or less, depending on screen size */

}


.modal-content-surveyor {
  background-color: #fefefe;
  margin: 9% auto 15% auto;
  /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  border-radius: 15px;
  width: 100%;
  /* Could be more or less, depending on screen size */

}

.modal-content-surveyor h1 {
  flex-grow: 1;
  margin: 0;
}

/* Full-page view for the editor modal */
.editor-modal {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(255, 255, 255);
  /* White background */
  /* padding-top: 20px; */
  padding: 1%;
  box-shadow: none;
  /* Remove box shadow */
  border-radius: 0;
  /* Remove border radius */
  z-index: 1;
}

/* Modal Content/Box for the full-page view */
.editor-modal-content,
.editor-modal-content-new {
  margin-top: 1%;
  background-color: #fefefe;
  /* margin: 0 auto; */
  /* border: 1px solid #888; */
  /* border-radius: 15px; */
  width: 100%;
  /* max-width: 1200px;  */
  /* padding: 20px; */
  /* box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 15px 30px rgba(68, 68, 68, 0.1); */
}



.rounded-input {

  border-radius: 7px;
}

.password-input-container {
  position: relative;
}


.toggle-password-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-38%);
  cursor: pointer;
}

.modal-date-btn {
  background-color: #2f2ab7;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  width: 100px;
  min-width: fit-content;
}

.modal-date-btn:hover {
  background-color: #272398;
  min-width: fit-content;
}



.modal-submit-btn {
  background-color: #42b72a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  width: 100px;
  min-width: fit-content;
}

.modal-submit-btn:hover {
  background-color: #36a420;
  min-width: fit-content;
}

.modal-submit-btn-login {
  background-color: #0866ff;
  border: none;
  border-radius: 6px;
  color: white;
  margin-top: 25px;

}

.modal-submit-btn-new {
  background-color: #8c847a;
  border: 1px solid #888;
  border-radius: 15px;
  width: 80px;
  min-width: fit-content;
  color: white;
}

.modal-input-btn {
  background-color: #033a11;
  border: 1px solid #888;
  border-radius: 15px;
  color: white;
  padding: 10px;
  width: 100%;
  margin-top: 20px;
}

.modal-close-btn {
  background-color: #ca290dfb;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  width: 100px;
  min-width: fit-content;
}

.modal-close-btn:hover {
  background-color: #a21818cd;
  min-width: fit-content;
}

.modal-preview-btn {
  background-color: #1877f2;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: 0.3s;
  width: 100px;
  min-width: fit-content;
}

.modal-preview-btn:hover {
  background-color: #216fdb;
  min-width: fit-content;
}

.modal-choose-btn {
  background-color: #e4e6eb;
  color: #4b4f56;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  width: 100%;
}

.modal-reject-btn {
  background-color: #b72a2a;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  width: 100px;
  min-width: fit-content;
}

.modal-reject-btn:hover {
  background-color: #a12626;
  min-width: fit-content;
}

/* Add Zoom Animation */
.animate {
  -webkit-animation: animatezoom 0.6s;
  animation: animatezoom 0.6s
}

@-webkit-keyframes animatezoom {
  from {
    -webkit-transform: scale(0)
  }

  to {
    -webkit-transform: scale(1)
  }
}

@keyframes animatezoom {
  from {
    transform: scale(0)
  }

  to {
    transform: scale(1)
  }
}

.container {
  max-width: auto;
  margin: 1 auto;
  gap: 10px;
  /* margin-bottom: 1rem; */
  padding: 0 20px;
  border: none;
  border-color: rgb(24, 24, 98);
  align-items: center;
  display: flex;
  justify-content: center;
}

.surveyorcontainer {
  max-width: auto;
  margin: 1 auto;
  margin-bottom: 1rem;
  padding: 0 20px;
  border: none;
  border-color: rgb(24, 24, 98);
  align-items: center;
  display: flex;
  justify-content: center;
}

.card {
  min-height: 50px;
  min-width: 200px;
  display: flex;
  margin: 1 auto;
  /* padding: 0 20px; */
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border: 10px, solid;
  border-color: rgb(24, 24, 98);
  font: 12px sans-serif;
  border-radius: 15px;
  background-color: black;

}

.inprogress {
  min-height: 50px;
  min-width: 200px;
  display: inline-block;
  padding: 0 20px;
  margin: 0.5rem 5px;
  border: solid;
  border-color: #d6e8f7;
  font: 12px sans-serif;
  border-radius: 15px;
  background-color: #d6e8f7;
  width: 99%;
}

.completed {
  min-height: 50px;
  min-width: 200px;
  display: inline-block;
  padding: 0 20px;
  margin: 0.5rem 5px;
  border: solid;
  border-color: #d6e8f7;
  font: 12px sans-serif;
  border-radius: 15px;
  background-color: #d6e8f7;
  width: 99%;
}

.approved {
  min-height: 50px;
  min-width: 200px;
  display: inline-block;
  padding: 0 20px;
  margin: 0.5rem 5px;
  border: solid;
  border-color: #d6e8f7;
  font: 12px sans-serif;
  border-radius: 15px;
  background-color: #d6e8f7;
  width: 99%;
}

.newitem {
  min-height: 50px;
  min-width: 200px;
  display: inline-block;
  margin: 1 auto;
  padding: 0 20px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border: solid;
  border-color: #494848;
  font: 12px sans-serif;
  border-radius: 15px;
  align-items: center;
  background-color: #494848;


}

.surveyornewitem {
  min-height: 50px;
  min-width: 200px;
  display: inline-block;
  padding: 0 20px;
  border: solid;
  margin: 0.5rem 5px;
  border-color: #d6e8f7;
  font: 12px sans-serif;
  border-radius: 15px;
  align-items: center;
  background-color: #d6e8f7;
  width: 99%;
}

.childcard {
  min-height: 20px;
  min-width: 100px;
  display: inline-block;
  margin: 1 auto;
  padding: 0 20px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border: solid 1px;
  border-color: #b3d8f7;
  font: 12px sans-serif;
  background-color: #e6f3fd;
  border-radius: 5px;
  align-items: center;
  text-align: center;

}

.newChildCard {
  width: 100%;
  min-height: 20px;
  min-width: 100px;
  display: inline-block;
  padding: 0 1rem 1rem 1rem;
  border: solid 1px;
  border-color: #b3d8f7;
  font: 12px sans-serif;
  background-color: #e6f3fd;
  border-radius: 5px;
  align-items: center;
  text-align: center;
  margin: 1rem auto;
}

.square-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: #f2f2f2;
  color: green;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.submodal-container {
  position: relative;
  padding: 40px;

}

.cross-button {
  position: absolute;
  top: 1.5px;
  right: 1.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: #e05050;
  color: #f2f2f2;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.custom-multiselect {
  position: relative;
  grid-template-columns: auto, auto;
  width: 100%;
}

.custom-select {
  position: relative;
  grid-template-columns: auto, auto;
  width: 100%;
  align-items: center;
}

.select-container {
  display: flex;

}

.button-container {
  margin-left: 10px;
  /* Adjust the margin as per your preference */
}

.MuiTypography-h6 {
  font-size: 16px !important;
  color: rgb(23, 7, 7);
  overflow: auto;
  font-family: "Segoe UI Historic" !important;
}

.MuiTableCell-body {
  font-size: 12px !important;
  font-family: "Segoe UI Historic" !important;
}

.label {
  font-weight: bold;
  color: #070e51;
  background-color: #c9c9c9;
  border: #c9c9c9;
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 16px !important;
  font-family: "Segoe UI Historic" !important;
}

.template-name-item {
  cursor: pointer;
  font-size: 16px !important;
  font-family: "Segoe UI Historic" !important;
}



.template-names-container {
  display: flex;
  justify-content: space-between;
  gap: 1px;
  align-items: center;
}

.left-column,
.right-column {
  width: 60%;
  border: 1px solid #ccc;
  padding: 10px;
  max-height: 300px;
  min-height: 300px;
  overflow-y: auto;
}

.selected-name-item {
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 16px !important;
  font-family: "Segoe UI Historic" !important;
}


.temp {
  font-weight: bold;
  color: #070e51;
  background-color: #e4e2e2;
  border: #c9c9c9;
  margin-top: 0%;
  margin-bottom: 7px;
  text-align: center;
  border-radius: 4px;
  font-size: 16px !important;
  font-family: "Segoe UI Historic" !important;
}


.select-container {
  margin-top: 10px;
}




.select-dropdown {
  width: 20%;
  padding: 8px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}


.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;

}



.cross-buttonsurvey {
  top: 114px;
  right: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: #e05050;
  color: #f2f2f2;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.error-message {
  color: red;
  font-size: 12px !important;
  font-family: Segoe UI Historic !important;
}

/* Add your custom styles for the "View More" button here */
.view-more-button {
  background-color: #9dc8ea;
  color: #042b85;
  border-radius: 10px;
  border: none;
  padding: 15px 10px;
  width: 300px;
  margin: 10px auto;
  cursor: pointer;
  font-weight: bold;
}

.cross-button-SurveyorDashboard {
  top: 114px;
  right: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  background-color: #e05050;
  color: #f2f2f2;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.btn-container-multiple {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  gap: 1%;
  flex-shrink: 0;
  /* Prevent buttons from shrinking */
  min-width: fit-content;

}

.btn-container-multiple-editor {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
  gap: 1%;
  flex-shrink: 0;
  /* Prevent buttons from shrinking */
  min-width: fit-content;

}

@media (max-width: 768px) {
  .btn-container-multiple-editor {
    overflow-x: scroll;
    justify-content: flex-start;

  }
}


.formregister {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%;
  background-color: #fff;
  padding: 10px 20px 20px 20px;
  border-radius: 20px;
  position: relative;
  margin-top: -25px;
}


.titlereg {
  font-family: Segoe UI Historic;
  font-size: 26px;
  color: #525f7f;
  font-weight: 600;
  letter-spacing: -1px;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 22px;
}


.heightbox {
  padding: 2px;
}


@media only screen and (max-width: 600px) {
  .titlereg {
    font-size: 2.5rem;
  }
}



.messagereg,
.signin {
  color: rgba(88, 87, 87, 0.822);
  font-size: 14px;
}

.signin {
  text-align: center;
}

.signin a {
  color: royalblue;
}

.signin a:hover {
  text-decoration: underline royalblue;
}

.flexreg {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 6px;
}


.formregister label .inputreg {
  width: 100%;
  padding: 5px;
  margin: 0px;
  outline: 0;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 10px;
  font-size: 16px;
}

.overflow-createsurvey {
  max-height: 550px;
  /* Set a maximum height for the form */
  overflow: auto;
  /* Enable scrolling */
  width: 100% !important;
  padding: 7px 0 0 0 !important;
  /* Simplified padding */
}

/* .formregister label .inputreg + span {
  position: absolute;
  left: 10px;
  top: 15px;
  color: grey;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
} */

/* .formregister label .inputreg:placeholder-shown + span {
  top: 15px;
  font-size: 0.9em;
} */

/* .formregister label .inputreg:focus + span,.form label .inputreg:valid + span {
  top: 30px;
  font-size: 0.7em;
  font-weight: 600;
} */

/* .formregister label .inputreg:valid + span {
  color: rgb(0, 255, 106);
} */
.inputreg {
  width: 70%;
  box-sizing: border-box;
  /* margin-bottom: 10px; */
}

.submitreg {
  border: none;
  outline: none;
  background-color: royalblue;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  transform: .3s ease;
}

.submitreg:hover {
  background-color: rgb(56, 90, 194);
}

@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}

.path {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.path h2 {
  font-family: "Segoe UI Historic";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 20px;
}

.path h2.inactive {
  color: #8B8B8B;
}

.path h2.active {
  color: #4C4C4C;
}


.path h {
  font-family: "Segoe UI Historic";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 20px;
}

.path h.inactive {
  color: #8B8B8B;
}

.path h.active {
  color: #4C4C4C;
}

.active-heading {
  font-size: 15px;
  margin-top: -6px;
  margin-left: 5px;
  font-weight: 600;
  font-family: Segoe UI Historic;
}

.new-editor-wrapper {
  background-color: white;
  border-radius: 20px;
  padding: 1%;
  overflow: hidden;
}

.certificate-name {
  text-wrap: nowrap;
}

.table-head-new {
  font-family: Segoe UI Historic !important;
  font-weight: 600 !important;
  text-wrap: nowrap;
  background-color: #e0e0e0 !important;
  padding: 10px !important;
}

.table-head-new-more {
  font-weight: 600 !important;
  font-family: Segoe UI Historic !important;
  text-wrap: nowrap;
  font-size: 18px;
  padding: 1%;
  margin-left: -5px;
}

.table-new-head {
  background-color: #e0e0e0 !important;
  font-family: "Segoe UI Historic" !important;
}

/*.css-1ps6pg7-MuiPaper-root {
    margin-top: -19px;
}
.css-mhc70k-MuiGrid-root > .MuiGrid-item {
    padding-left: 0px !important;
}
.css-mhc70k-MuiGrid-root > .MuiGrid-item {
    padding-top: 0px !important;
}
 */
.input-container {
  position: relative;
}

.required-star {
  position: absolute;
  left: -10px;
  /* Adjust to fit your design */
  top: 50%;
  transform: translateY(-50%);
  color: red;
  font-weight: bold;
  z-index: 1;
}
.required-surveyor {
  margin-left:-8px;
  left: -10px;
  /* Adjust to fit your design */
  top: 50%;
  transform: translateY(-50%);
  color: red;
  font-weight: bold;
  z-index: 1;
}

.vessel-label {
  font-family: "Segoe UI Historic" !important;
  font-size: 15px !important;
  margin-left: 6px;
}

.heading-dash {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 0;
  margin-left: 18px;
  font-size: 26px !important;
  font-family: Segoe UI Historic !important;
}

.heading-survey {
  text-align: left;
  font-size: 26px;
  margin-left: 1px;
  margin-top: 0;
  margin: 0;
  font-family: Segoe UI Historic !important;
}

.header-surveyor {
  text-align: left;
  font-size: 26px;
  font-family: Segoe UI Historic !important;
  margin-top: -7px;
  margin-left: 1px;
}

.table-cell-survey {
  padding: 0px !important;
  padding-left: 10px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.table-cell-new {
  font-family: Segoe UI Historic;
  /* font-weight: bold !important;  */
  padding: 0px !important;
  padding-left: 10px !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.table-grid-new-more {
  font-family: Segoe UI Historic;
  font-weight: bold !important;
  font-size: 16px;
}

.dropdown-list {
  font-family: Segoe UI Historic !important;
}

.heading-template {
  font-family: Segoe UI Historic !important;
  font-size: 16px;
}

.select-template {
  font-family: Segoe UI Historic !important;
  font-size: 14px;
}

.client-add-heading {
  text-align: left;
  font-size: 18px !important;
}

.success-message {
  font-family: Segoe UI Historic !important;
  font-size: 14px;
  color: rgb(7, 170, 7);
}

.loader-text {
  font-size: 30px;
  color: black;
  font-family: Segoe UI Historic !important;
}